import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../services/auth.guard';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DashboardComponent } from './dashboard.component';

import { WelcomeComponent } from './welcome/welcome.component';

const routes: Routes = [
  {
    path: "",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", component: WelcomeComponent },

      {
        path: "brands",
        loadChildren: () => import("./brands/brands.module").then((m) => m.BrandsModule),
      },
      {
        path: "group",
        loadChildren: () => import("./groups/groups.module").then((m) => m.GroupsModule),
      },
      {
        path: "recommend",
        loadChildren: () => import("./recommendation/recommendation.module").then((m) => m.RecommendationModule),
      },
      {
        path: "indicator",
        loadChildren: () => import("./indicator/indicator.module").then((m) => m.IndicatorModule),
      },
      {
        path: "stage",
        loadChildren: () => import("./stage/stage.module").then((m) => m.StageModule),
      },
      {
        path: "touchpoint",
        loadChildren: () => import("./touchpoint/touchpoint.module").then((m) => m.TouchpointModule),
      },
      {
        path: "task",
        loadChildren: () => import("./task/task.module").then((m) => m.TaskModule),
      },
      {
        path: "question",
        loadChildren: () => import("./question/question.module").then((m) => m.QuestionModule),
      },
      {
        path: 'department',
        loadChildren: () => import('./department/department.module').then((m) => m.DepartmentModule),
      },
      {
        path: 'experience',
        loadChildren: () => import('./experience/experience.module').then((m) => m.ExperienceModule),
      },
      {
        path: 'scenario',
        loadChildren: () => import('./scenario/scenario.module').then((m) => m.ScenarioModule),
      },
      // {
      //   path: "recom-category",
      //   loadChildren: () =>
      //     import("./recommendation-category/recommendation-category.module").then(
      //       (m) => m.RecommendationCategoryModule
      //     ),
      // },
      // {
      //   path: "recommendation",
      //   loadChildren: () => import("./recommendation/recommendation.module").then((m) => m.RecommendationModule),
      // },
      {
        path: "client",
        loadChildren: () => import("./client/client.module").then((m) => m.ClientModule),
      },

      {
        path: "users",
        loadChildren: () => import("./users/users.module").then((m) => m.UsersModule),
      },

      {
        path: "survey",
        loadChildren: () => import("./survey/survey.module").then((m) => m.SurveyModule),
      },
      {
        path: "complains",
        loadChildren: () => import("./complains/complains.module").then((m) => m.ComplainsModule),
      },
      { path: "change-password", component: ChangePasswordComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
