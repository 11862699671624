import { Component, OnInit } from '@angular/core';

declare var $: any;
declare var init: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    // $('document').ready(function () {
    //   var $slimScrolls = $('.slimscroll');

    //   if ($slimScrolls.length) {
    //     $slimScrolls.slimScroll({
    //       height: 'auto',
    //       width: '100%',
    //       position: 'right',
    //       size: '7px',
    //       color: '#ccc',
    //       allowPageScroll: false,
    //       wheelStep: 10,
    //       touchScrollStep: 100,
    //     });
    //     var wHeight = $(window).height() - 60;
    //     $slimScrolls.height(wHeight);
    //     $('.sidebar .slimScrollDiv').height(wHeight);
    //     $(window).resize(function () {
    //       var rHeight = $(window).height() - 60;
    //       $slimScrolls.height(rHeight);
    //       $('.sidebar .slimScrollDiv').height(rHeight);
    //     });
    //   }
    // });
  }
}
