// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // dev
  apiUrl: 'https://dev.api.mysterya.io/api/v1',
  // apiUrl: 'http://localhost:8000/api/v1', // todo change after fix deploy
  production: false,
};

// action column,fee along input,duration in min,make hiper link, add cancel order

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

// group level
// faisal1123@yopmail.com
// uWVXKlTb

// brand level
// faisal1124@yopmail.com
// uwlsVsHl
