export interface ApiResponse {
  data: any;
  message: string;
  status: boolean;
  statusCode: number;
}

export enum PaymentStatus {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
}

export enum PaymentMethod {
  PREPAID = 'PREPAID',
  COD = 'COD',
}

export enum OrderStatus {
  CREATED = 'CREATED',
  CANCELLED = 'CANCELLED',
  DELIVERED = 'DELIVERED',
  ORDERED = 'ORDERED',
  PACKED = 'PACKED',
  SHIPPED = 'SHIPPED',
  RETURENED = 'RETURENED',
}

export enum ApprovalStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DISAPPROVED = 'DISAPPROVED',
}

export interface PageData {
  count: number;
  docs: any[];
}

export interface PaginationData {
  rows: any[];
  totalCount: number;
  hasPrev2: true;
  hasPrev: true;
  isCurrentPage: true;
  hasNext: true;
  hasNext2: true;
}

export interface AuthData {
  email: string;
  name: string;
  id: string;
  mobileNumber: string;
  token: string;
  role: string;
}
export interface Complain {
  userId: { first_name: string; last_name: string; avatar: string; email: string; _id: string };
  surveyId: { survey_name: string; _id: string };
  reason: string;
  content: string;
  _id: string;
  createdAt: string;
}
export interface User {
  additional_detail: {
    location: { type: string; coordinates: number[] };
    nationality: string;
    city: string[];
    language: string[];
    country: string;
  };
  connected_groups: string[];
  bank_detail: {
    bank_name: string;
    country: string;
    city: string;
    iban: string;
    account_holder: string;
    account_number: string;
    profile_image: string;
    swift_info: string;
  };
  email: string;
  password: string;
  referal_code: string;
  applied_referal_code: string;
  gender: string;
  additional_detail_filled: boolean;
  bank_detail_filled: boolean;
  isActive: boolean;
  deleted: boolean;
  _id: string;
  role: string;
  first_name: string;
  last_name: string;
  phoneNumber: string;
  avatar: string;
  createdAt: string;
  updatedAt: string;
  isReferalUser: Boolean;
  isRefApprove: Boolean;
  age: number;
  otp: string;
  otpExpiration: string;
}

export interface surveyInfo {
  instructions: {
    pdfFiles: [];
    videoInstructions: {
      videoTitle: "";
      videoUrl: "";
    };
  };
  survey_name: string;
  group: { _id: string; name: string };
  brand: { _id: string; name: string };
  currency: string;
  price: number;
  isActive: boolean;
  survay_questions_length: number;
  task: { _id: string; name: string };
  surveyer_spoc_name: string;
  surveyer_spoc_email: string;
  surveyer_spoc_mobile: string;
  surveyer_spoc_designation: string;
  surveyer_spoc_image: string;
  description: string;
  training_by_date: string;
  visit_by_date: string;
  visit_by_date_to: string;
  submission_by_date: string;
  city: string;
  country: string;
  expenses_from: number;
  expenses_to: number;
  outlet: string;
  channels: string;
  terms_and_condition: string;
  non_discloser_agreement: string;
  surveyInfoCompleted: boolean;
  instructionCompleted: boolean;
  questionCompleted: boolean;
  deleted: boolean;
  _id: string;
  connected_users: {
    status: string;
    startAddress: string;
    endAddress: string;
    _id: string;
    user_id: {
      email: string;
      _id: string;
      first_name: string;
      last_name: string;
    };
  }[];
  questionSections: {
    heading: String;
    questions: { question: string }[];
  }[];
  questions: [
    {
      question: string;
      touch_points: string[];
      stages: string[];
      indicators: string[];
    }
  ];
  createdAt: string;
  updatedAt: string;
}

export interface Question {
  _id: string;
  question: string;
  isActive: boolean;
  createdAt: number;
  updatedAt: number;
}
// Generated by https://quicktype.io

export interface Category {
  name: string;
  isActive: boolean;
  group: string[];
  _id: string;
}

export interface Stage {
  _id: string;
  name: string;
  isActive: boolean;
}
export interface Indicator {
  _id: string;
  name: string;
  isActive: boolean;
}
export interface TouchPoint {
  _id: string;
  name: string;
  isActive: boolean;
}
export interface Brand {
  _id: string;
  name: string;
  image: string;
  isActive: boolean;
}
export interface Group {
  _id: string;
  name: string;
  image: string;
  //connected_brands:string[];
  connected_brands: {
    _id: string;
    brand_id: {
      _id: string;
      name: string;
    };
  }[];
  isActive: boolean;
}
export interface Task {
  _id: string;
  name: string;
  isActive: boolean;
}

export interface Department {
  _id: string;
  name: string;
  isActive: boolean;
}

export interface Experience {
  _id: string;
  name: string;
  isActive: boolean;
}
// Generated by https://quicktype.io

export interface Recommendation {
  name: string;
  description: string[];
  attachment: string;
  isActive: boolean;
  _id: string;
  category: Category;
}

export enum userRole {
  ADMIN = 'ADMIN',
  SUB_ADMIN = 'SUB_ADMIN',
  USER = 'USER',
  CLIENT = 'CLIENT',
}

export interface Scenario {
  name: string;
  description: string;
  isActive: boolean;
  _id: string;
  users: {
    _id: string;
    user_id: {
      email: string;
      _id: string;
      first_name: string;
      last_name: string;
    };
    type: userRole;
  }[];
  questionSections: {
    heading: String;
    questions: { question: string }[];
  }[];
  questions: [
    {
      question: string;
      touch_point: string[];
      stage: string[];
      indicator: string[];
      department: string[];
      experience: string[];
    }
  ];
  createdAt: string;
  updatedAt: string;
}


/*
export interface Variant {
  SKU: string;
  color: string;
  material: string;
  MRP: number;
  sellingPrice: number;
  quantity: number;
  size: string;
  _id: string;
}*/
/*
export interface VendorData {
  _id: string;
  address: { street: string; pinCode: string; city: string; state: string };
  email: string;
  mobileNumber: string;
  isApproved: string;
  businessType: string;
  isActive: boolean;
  isProfileComplete: boolean;
  gstNumber: string;
  panNumber: string;
  addressProof: string[];
  date: string;
  commissionPercent: number;
  isMobileVerified: boolean;
  isEmailVerified: boolean;
  deleted: boolean;
  name: string;
  brandLogo: string;
  createdAt: string;
  updatedAt: string;
}

export interface Product {
  name: string;
  description: string;
  brand: string;
  images: string[];
  sku: string;
  GST: number;
  fabricInformation: string;
  washCareInfo: string;
  leadTime: string;
  price: number;
  totalQuantityAvailable: number;
  sizesAvailable: string[];
  colorsAvailable: string[];
  status: boolean;
  deleted: false;
  _id: string;
  categories: Category[];
  variantOptions: any[];
  variants: Variant[];
  vendor?: VendorData;
  isApproved: string;
  quantityDependent: boolean;
  metaDescription: string;
  rating: number;
  ratingCount: number;
  isFeatured: boolean;
  createdBy: string;
  date: Date;
  createdAt: string;
  updatedAt: string;
  __v: number;
  category: Category;
  subCategories: Category[];
  tags: string[];
}

export interface Collection {
  conditions: {
    _id: string;
    field: string;
    condition: string;
    value: string;
  }[];
  createdAt: Date;
  createdBy: string;
  deleted: boolean;
  description: string;
  chartImage: string;
  image: string;
  mustMatchAll: boolean;
  title: string;
  status: boolean;
  type: "MANUAL" | "AUTOMATED";
  updatedAt: Date;
  date: Date;
  isFeatured: boolean;
  __v: number;
  _id: string;
}
*/

/*
export interface Slide {
  title: string;
  desc: string;
  imageUrl: string;
  redirectUrl: string;
  _id: string;
}

export interface Slider {
  _id: string;
  tag: string;
  slides: Slide[];
  createdAt: string;
  updatedAt: string;
  slug: string;
  __v: string;
}

export interface OrderItem {
  _id: string;
  item: Product;
  quantity: number;
  variant: {
    price: number;
    comparePrice: number;
    costPrice: number;
    quantity: number;
    SKU: string;
    deleted: boolean;
    _id: string;
  };
  orderStatus: {
    ORDERED: { status: boolean; date: string };
    PACKED: { status: boolean; date: string };
    SHIPPED: { status: boolean; date: string };
    DELIVERED: { status: boolean; date: string };
    CANCELLED: { status: boolean; date: string };
    RETURENED: { status: boolean; date: string };
  };
}

export interface OrderAddress {
  name: string;
  email: string;
  mobileNumber: string;
  address: string;
  landmark: string;
  houseNumber: string;
  city: string;
  pinCode: string;
  state: string;
  date: Date;
}

export interface Order {
  _id: string;
  paymentStatus: PaymentStatus;
  isValidSignature: boolean;
  date: string;
  shippingCharge: number;
  currentOrderStatus: OrderStatus;
  total: number;
  quantity: number;
  deleted: false;
  orderId: string;
  product: Product;
  variant: Variant;
  vendor: Vendor;
  orderStatus: {
    _id: string;
    status: OrderStatus;
    date: string;
    msg: string;
  }[];
  paymentMethod: PaymentMethod;
  shippingAddress: Address;
  user: User;
  createdAt: string;
  updatedAt: string;
  rPayOrderId: String;
  rPayPaymentId: String;
  rPaySignature: String;
  reasonMessage: String;
}

export interface Coupon {
  status: boolean;
  deleted: boolean;
  isPrivate: boolean;
  appliedCount: number;
  discountIn: string;
  discount: number;
  numberOfUsers: number;
  _id: string;
  code: string;
  createBy: string;
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;
  date: Date;
}*/

// Generated by https://quicktype.io
/*
export interface IngAddress {
  _id: string;
  name: string;
  mobileNumber: string;
  email: string;
  landmark: string;
  address: string;
  houseNumber: string;
  city: string;
  pinCode: string;
  state: string;
  country: string;
  date: string;
  isDefault: boolean;
  deleted: boolean;
  tag: string;
  user: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface AwbData {
  awb: string;
  applied_weight: string;
  charged_weight: string;
  billed_weight: string;
  routing_code: string;
  rto_routing_code: string;
  charges: Charges;
}

export interface Charges {
  zone: string;
  cod_charges: string;
  applied_weight_amount: string;
  freight_charges: string;
  applied_weight: string;
  charged_weight: string;
  charged_weight_amount: string;
  charged_weight_amount_rto: string;
  applied_weight_amount_rto: string;
  service_type_id: string;
}

export interface ExtraInfo {
  order_type: string;
  is_document: number;
  order_metadata: OrderMetadata;
  amazon_dg_status: boolean;
  bluedart_dg_status: boolean;
  other_courier_dg_status: boolean;
}

export interface OrderMetadata {
  type: string;
  device: boolean;
  platform: string;
  client_ip: string;
  request_type: string;
}

export interface OrderInsurance {
  insurance_status: string;
  policy_no: string;
  claim_enable: boolean;
}

export interface Others {
  weight: number;
  quantity: number;
  self_ship: boolean;
  buyer_psid: null;
  dimensions: string;
  billing_city: string;
  billing_name: string;
  company_name: null;
  billing_email: string;
  billing_phone: string;
  billing_state: string;
  currency_code: null;
  package_count: number;
  reseller_name: null;
  customer_gstin: null;
  billing_address: string;
  billing_country: string;
  billing_pincode: string;
  billing_isd_code: string;
  shipping_charges: string;
  billing_address_2: null;
  is_order_verified: number;
  order_verified_at: string;
  billing_alternate_phone: string;
}

export interface Address {
  _id: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  address: string;
  city: string;
  pinCode: string;
  date: string;
  isDefault: true;
  deleted: false;
  user: string;
  createdAt: string;
  updatedAt: string;
}

export interface FluffyProduct {
  id: number;
  order_id: number;
  name: string;
  sku: string;
  channel_order_product_id: string;
  channel_sku: string;
  hsn: string;
  model: null;
  manufacturer: null;
  brand: null;
  color: null;
  size: null;
  custom_field: string;
  custom_field_value: string;
  custom_field_value_string: string;
  weight: number;
  dimensions: string;
  price: number;
  cost: number;
  mrp: number;
  quantity: number;
  tax: number;
  status: number;
  net_total: number;
  discount: number;
  product_options: any[];
  selling_price: number;
  tax_percentage: number;
  discount_including_tax: number;
  channel_category: string;
  packaging_material: string;
  additional_material: string;
  is_free_product: string;
}

export interface Shipments {
  id: number;
  order_id: number;
  order_product_id: null;
  channel_id: number;
  code: string;
  cost: string;
  tax: string;
  awb: null;
  awb_assign_date: null;
  etd: string;
  delivered_date: string;
  quantity: number;
  cod_charges: string;
  number: null;
  name: null;
  order_item_id: null;
  weight: number;
  volumetric_weight: number;
  dimensions: string;
  comment: string;
  courier: string;
  courier_id: string;
  manifest_id: string;
  manifest_escalate: boolean;
  status: string;
  isd_code: string;
  created_at: string;
  updated_at: string;
  pod: null;
  eway_bill_number: string;
  eway_bill_date: null;
  length: number;
  breadth: number;
  height: number;
  rto_initiated_date: string;
  rto_delivered_date: string;
  shipped_date: string;
  package_images: string;
  is_rto: boolean;
  eway_required: boolean;
  invoice_link: string;
}

export interface Item {
  _id: string;
  name: string;
  description: string;
  brand: null;
  images: string[];
  type: string;
  status: boolean;
  quantityDependent: boolean;
  isApproved: string;
  date: string;
  isFeatured: boolean;
  metaDescription: string;
  rating: number;
  ratingCount: number;
  viewCount: number;
  deleted: boolean;
  variantOptions: VariantOption[];
  vendor: Vendor;
  userRole: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  slug: string;
  __v: number;
}

export interface VariantOption {
  type: string;
  values: string[];
  _id: string;
}

export interface Vendor {
  _id: string;
  email: string;
  mobileNumber: string;
  isApproved: string;
  name: string;
  businessEmail: string;
  businessMobileNumber: string;
  isActive: boolean;
  isProfileComplete: boolean;
  gstNumber: string;
  panNumber: string;
  addressProof: string[];
  date: string;
  commissionPercent: number;
  isMobileVerified: boolean;
  isEmailVerified: boolean;
  deleted: boolean;
  brandLogo: string;
  createdAt: string;
  updatedAt: string;
}

export interface Label {
  Size: string;
}

// Generated by https://quicktype.io

export interface PickupLocation {
  id: number;
  pickup_location: string;
  address_type: null;
  address: string;
  address_2: string;
  city: string;
  state: string;
  country: string;
  pin_code: string;
  email: string;
  phone: string;
  name: string;
  company_id: number;
  gstin: null;
  vendor_name: null;
  status: number;
  phone_verified: number;
  lat: string;
  long: string;
  warehouse_code: null;
  alternate_phone: null;
  rto_address_id: null;
  lat_long_status: number;
  new: number;
  associated_rto_address: null;
}

export interface ContentData {
  subTitle: string;
  sortDescription: string;
  description: string;
  deleted: boolean;
  _id: string;
  title: string;
  createdAt: string;
  updatedAt: string;
  slug: string;
}

export interface FilterData {
  categories: Category[];
  items: string[];
  status: boolean;
  deleted: boolean;
  _id: string;
  heading: string;
  createdAt: string;
  updatedAt: string;
}

export interface PricesData {
  deleted: boolean;
  heading: string;
  _id: string;
  prices: {
    _id: string;
    from: number;
    to: number;
  }[];
  createdAt: string;
  updatedAt: string;
}

export interface Payout {
  isPayout: boolean;
  deleted: boolean;
  date: string;
  msg: string;
  _id: string;
  order: Order;
  createdAt: string;
  updatedAt: string;
  amount: number;
}
*/
