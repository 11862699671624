<div class="main-wrapper login-body">
  <div class="login-wrapper">
    <div class="container">
      <div class="loginbox">
        <div class="login-left">
          <img class="img-fluid" src="../../../assets/img/logo-3_2.svg" alt="Logo" />
        </div>
        <!-- send otp -->
        <div class="login-right" *ngIf="selectedForm == FormView.SEND_OTP">
          <div class="login-right-wrap" *ngIf="sendOtpForm">
            <h1>Forgot Password?</h1>
            <p class="account-subtitle">Enter your email to get a password reset OTP</p>

            <!-- Form -->
            <form [formGroup]="sendOtpForm" (ngSubmit)="submitSendOtpForm(sendOtpForm?.value)">
              <div class="form-group">
                <input
                  class="form-control"
                  [ngClass]="sendOtpForm.get('email')?.touched ? (sendOtpForm.get('email')?.errors ? 'is-invalid' : 'is-valid') : ''"
                  formControlName="email"
                  type="email"
                  pattern='^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'
                  placeholder="Email *"
                />
                <app-input-error [control]="sendOtpForm.get('email')!" [fieldName]="'Email'"> </app-input-error>
              </div>
              <div class="form-group mb-0">
                <button class="btn btn-primary btn-block" type="submit" [disabled]="isSubmitSendOtpForm">
                  <span *ngIf="isSubmitSendOtpForm" class="spinner-border spinner-border-sm mr-2" role="status"></span>
                  Generate OTP
                </button>
              </div>
            </form>
            <!-- /Form -->

            <div class="text-center dont-have">Remember your password? <a [routerLink]="['/login']">Login</a></div>
          </div>
        </div>
        <!-- send otp -->

        <!-- verify otp -->
        <div class="login-right" *ngIf="selectedForm == FormView.VERIFY_OTP">
          <div class="login-right-wrap" *ngIf="verifyOtpForm">
            <h1>Verify OTP</h1>
            <p class="account-subtitle">Enter OTP sent to your Email</p>

            <!-- Form -->
            <form [formGroup]="verifyOtpForm" (ngSubmit)="submitVerifyOtpForm(verifyOtpForm.value)">
              <div class="form-group">
                <input
                  [ngClass]="verifyOtpForm.get('otp')?.touched ? (verifyOtpForm.get('otp')?.hasError('required') ? 'is-invalid' : 'is-valid') : ''"
                  class="form-control"
                  formControlName="otp"
                  type="email"
                  pattern='^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'
                  placeholder="OTP *"
                />
                <app-input-error [control]="verifyOtpForm.get('otp')!" [fieldName]="'OTP'"> </app-input-error>
              </div>
              <div class="form-group mb-0">
                <button class="btn btn-primary btn-block" type="submit" [disabled]="isSubmitVerifyOtpForm">
                  <span *ngIf="isSubmitVerifyOtpForm" class="spinner-border spinner-border-sm mr-2" role="status"></span>
                  Verify OTP
                </button>
              </div>
            </form>
            <!-- /Form -->
          </div>
        </div>
        <!-- verify otp -->

        <!-- reset password -->
        <div class="login-right" *ngIf="selectedForm == FormView.RESET_PASSWORD">
          <div class="login-right-wrap" *ngIf="resetPasswordForm">
            <h1>Reset Password</h1>
            <p class="account-subtitle">Enter your New Password</p>
            <!-- Form -->
            <form [formGroup]="resetPasswordForm" (ngSubmit)="submitResetPasswordForm(resetPasswordForm.value)">
              <div class="form-group">
                <div class="input-group">
                  <input
                    #cPass
                    [ngClass]="resetPasswordForm.get('newPassword')?.touched ? (resetPasswordForm.get('newPassword')?.errors ? 'is-invalid' : 'is-valid') : ''"
                    class="form-control"
                    formControlName="newPassword"
                    [type]="passIsHide ? 'password' : 'text'"
                    placeholder="Password *"
                  />
                  <div class="input-group-append">
                    <div class="input-group-text cursor" (click)="passIsHide = !passIsHide">
                      <i [ngClass]="passIsHide ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                    </div>
                  </div>
                </div>

                <app-input-error [patternMsg]="REGX.PasswordMsg" [control]="resetPasswordForm.get('newPassword')!" [fieldName]="'New Password'">
                </app-input-error>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <input
                    #newPass
                    [ngClass]="
                      resetPasswordForm.get('confirmPassword')?.touched
                        ? resetPasswordForm.get('confirmPassword')?.errors ||
                          (!resetPasswordForm.get('confirmPassword')?.errors && newPass.value != cPass.value)
                          ? 'is-invalid'
                          : 'is-valid'
                        : ''
                    "
                    class="form-control"
                    formControlName="confirmPassword"
                    [type]="cpassIsHide ? 'password' : 'text'"
                    placeholder="Confirm Password *"
                  />
                  <div class="input-group-append">
                    <div class="input-group-text cursor" (click)="cpassIsHide = !cpassIsHide">
                      <i [ngClass]="cpassIsHide ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                    </div>
                  </div>
                </div>
                <app-input-error
                  *ngIf="resetPasswordForm.get('confirmPassword')?.errors"
                  [patternMsg]="REGX.PasswordMsg"
                  [control]="resetPasswordForm.get('confirmPassword')!"
                  [fieldName]="'Confirm Password'"
                >
                </app-input-error>

                <p class="block invalid-feedback" *ngIf="!resetPasswordForm.get('confirmPassword')?.errors && newPass.value != cPass.value">
                  New password and Confirm password must be same
                </p>

                <p *ngIf="!resetPasswordForm.get('confirmPassword')?.errors && newPass.value == cPass.value" class="block valid-feedback">Looks good!</p>
              </div>
              <div class="form-group mb-0">
                <button class="btn btn-primary btn-block" type="submit" [disabled]="isSubmitResetPasswordForm">
                  <span *ngIf="isSubmitResetPasswordForm" class="spinner-border spinner-border-sm mr-2" role="status"></span>
                  Reset Password
                </button>
              </div>
            </form>
            <!-- /Form -->
          </div>
        </div>
        <!-- reset password -->
      </div>
    </div>
  </div>
</div>
