<div class="page-wrapper">
  <div class="content container-fluid">
    <!-- Page Header -->
    <div class="page-header">
      <div class="row">
        <div class="col-sm-7 col-auto">
          <h3 class="page-title">Change Password</h3>
        </div>
        <div class="col-sm-5 col">
          <button
            (click)="location.back()"
            class="btn btn-primary btn-sm float-right mt-2"
          >
          <i class="fa fa-arrow-left"></i> Back
          </button>
        </div>
      </div>
    </div>
    <!-- /Page Header -->

    <div class="row">
      <div class="col-sm-12 col-md-6 offset-md-3">
        <div class="card">
          <div class="card-body">
            <form [formGroup]="form" (ngSubmit)="submitForm(form.value)">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="required-label" for="validationServer01"
                      >Old Password</label
                    >
                    <div class="input-group">
                      <input
                        #oldPass
                        [ngClass]="
                          form.get('oldPassword')?.touched
                            ? form.get('oldPassword')?.errors
                              ? 'is-invalid'
                              : 'is-valid'
                            : ''
                        "
                        [type]="oldPassIsHide ? 'password' : 'text'"
                        class="form-control"
                        id="validationServer01"
                        placeholder="Old Password"
                        formControlName="oldPassword"
                        required
                      />
                      <div class="input-group-append">
                        <div
                          class="input-group-text cursor"
                          (click)="oldPassIsHide = !oldPassIsHide"
                        >
                          <i
                            [ngClass]="
                              oldPassIsHide ? 'fa fa-eye-slash' : 'fa fa-eye'
                            "
                          ></i>
                        </div>
                      </div>
                    </div>

                    <app-input-error
                      [patternMsg]="REGX.PasswordMsg"
                      [control]="form.get('oldPassword')!"
                      [fieldName]="'Old Password'"
                    >
                    </app-input-error>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="required-label" for="validationServer01"
                      >New Password</label
                    >
                    <div class="input-group">
                      <input
                        #newPass
                        [ngClass]="
                          form.get('newPassword')?.touched
                            ? form.get('newPassword')?.errors
                              ? 'is-invalid'
                              : 'is-valid'
                            : ''
                        "
                        [type]="newPassIsHide ? 'password' : 'text'"
                        class="form-control"
                        id="validationServer01"
                        placeholder="New Password"
                        formControlName="newPassword"
                        required
                      />
                      <div class="input-group-append">
                        <div
                          class="input-group-text cursor"
                          (click)="newPassIsHide = !newPassIsHide"
                        >
                          <i
                            [ngClass]="
                              newPassIsHide ? 'fa fa-eye-slash' : 'fa fa-eye'
                            "
                          ></i>
                        </div>
                      </div>
                    </div>
                    <app-input-error
                      [patternMsg]="REGX.PasswordMsg"
                      [control]="form.get('newPassword')!"
                      [fieldName]="'New Password'"
                    >
                    </app-input-error>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="required-label" for="validationServer01"
                      >Confirm Password</label
                    >
                    <div class="input-group">
                      <input
                        #cPass
                        [ngClass]="
                          form.get('confirmPassword')?.touched
                            ? form.get('confirmPassword')?.errors ||
                              (!form.get('confirmPassword')?.errors &&
                                newPass.value != cPass.value)
                              ? 'is-invalid'
                              : 'is-valid'
                            : ''
                        "
                        [type]="cPassIsHide ? 'password' : 'text'"
                        class="form-control"
                        id="validationServer01"
                        placeholder="Confirm Password"
                        formControlName="confirmPassword"
                        required
                      />
                      <div class="input-group-append">
                        <div
                          class="input-group-text cursor"
                          (click)="cPassIsHide = !cPassIsHide"
                        >
                          <i
                            [ngClass]="
                              cPassIsHide ? 'fa fa-eye-slash' : 'fa fa-eye'
                            "
                          ></i>
                        </div>
                      </div>
                    </div>
                    <app-input-error
                      *ngIf="form.get('confirmPassword')?.errors"
                      [patternMsg]="REGX.PasswordMsg"
                      [control]="form.get('confirmPassword')!"
                      [fieldName]="'Confirm Password'"
                    >
                    </app-input-error>

                   
                    <p
                      class="block invalid-feedback"
                      *ngIf="
                        !form.get('confirmPassword')?.errors &&
                        newPass.value != cPass.value
                      "
                    >
                      New password and Confirm password must be same
                    </p>

                    <p
                      *ngIf="
                        !form.get('confirmPassword')?.errors &&
                        newPass.value == cPass.value
                      "
                      class="block valid-feedback"
                    >
                      Looks good!
                    </p>
                  </div>
                </div>
              </div>

              <button
                class="btn btn-primary float-right"
                type="submit"
                [disabled]="isFormSubmitted"
              >
                <span
                  *ngIf="isFormSubmitted"
                  class="spinner-border spinner-border-sm mr-2"
                  role="status"
                ></span>
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /ADD Modal -->
  </div>
</div>
