<!-- Main Wrapper -->
<div class="main-wrapper">
  <div class="error-box">
    <h1>500</h1>
    <h3 class="h2 mb-3">
      <i class="fa fa-warning"></i> Oops! Something went wrong
    </h3>
    <p class="h4 font-weight-normal">
      {{ message ? message : "Resource you requested was not found." }}
    </p>
    <a [routerLink]="['/']" class="btn btn-primary">Back to Home</a>
  </div>
</div>
<!-- /Main Wrapper -->
