<div class='sidebar' id='sidebar'>
  <div class='sidebar-inner slimscroll'>
    <div id='sidebar-menu' class='sidebar-menu'>
      <ul class='text-white'>
        <li class='menu-title'>
          <span>Main</span>
        </li>
        <li
          [routerLink]="['']"
          routerLinkActive='active'
          [routerLinkActiveOptions]='{ exact: true }'
        >
          <a [routerLink]="['']">
            <i class='fe fe-home'></i> <span>Dashboard</span></a
          >
        </li>

        <li
          [routerLink]="['/brands']"
          [queryParams]='queryParams()'
          [ngClass]="isLinkActive('/brands') ? 'active' : ''"
        >
          <a class='cursor'
          ><i class='fe fe-distribute-vertically'></i> <span>Brands</span></a
          >
        </li>
        <li
          [routerLink]="['/group']"
          [queryParams]='queryParams()'
          [ngClass]="isLinkActive('/group') ? 'active' : ''"
        >
          <a class='cursor'><i class='fe fe-tiled'></i> <span>Groups</span></a>
        </li>
        <li
          [routerLink]="['/client']"
          [queryParams]='queryParams()'
          [ngClass]="isLinkActive('/client') ? 'active' : ''"
        >
          <a class='cursor'><i class='fe fe-user'></i> <span>Clients</span></a>
        </li>
        <li
          [routerLink]="['/touchpoint']"
          [queryParams]='queryParams()'
          [ngClass]="isLinkActive('/touchpoint') ? 'active' : ''"
        >
          <a class='cursor'
          ><i class='fe fe-tumbler-glass'></i> <span>TouchPoint</span></a
          >
        </li>
        <li
          [routerLink]="['/stage']"
          [queryParams]='queryParams(100)'
          [ngClass]="isLinkActive('/stage') ? 'active' : ''"
        >
          <a class='cursor'><i class='fe fe-layer'></i> <span>Stage</span></a>
        </li>
        <li
          [routerLink]="['/indicator']"
          [queryParams]='queryParams()'
          [ngClass]="isLinkActive('/indicator') ? 'active' : ''"
        >
          <a class='cursor'
          ><i class='fe fe-kitchen-cooker'></i> <span>Indicator</span></a
          >
        </li>
        <li
          [routerLink]="['/task']"
          [queryParams]='queryParams()'
          [ngClass]="isLinkActive('/task') ? 'active' : ''"
        >
          <a class='cursor'><i class='fe fe-vr'></i> <span>Tasks</span></a>
        </li>
        <li
          [routerLink]="['/question']"
          [queryParams]='queryParams()'
          [ngClass]="isLinkActive('/question') ? 'active' : ''"
        >
          <a class='cursor'
          ><i class='fe fe-question'></i> <span>Questions</span></a
          >
        </li>
        <li
          [routerLink]="['/department']"
          [queryParams]='queryParams()'
          [ngClass]="isLinkActive('/department') ? 'active' : ''"
        >
          <a class='cursor'
          ><i class='fa fa-group'></i> <span>Department</span></a
          >
        </li>
        <li
        [routerLink]="['/experience']"
        [queryParams]='queryParams()'
        [ngClass]="isLinkActive('/experience') ? 'active' : ''"
      >
        <a class='cursor'
        ><i class='fa fa-thumbs-up'></i> <span>Experience</span></a
        >
      </li>
        <li
          [routerLink]="['/scenario']"
          [queryParams]='queryParams()'
          [ngClass]="isLinkActive('/scenario') ? 'active' : ''"
        >
          <a class='cursor'
          ><i class='fa fa-leanpub'></i> <span>Scenario</span></a
          >
        </li>
        <!-- <li [routerLink]="['/collections']" [queryParams]="queryParams()" routerLinkActive="active">
          <a [routerLink]="['/collections']" [queryParams]="queryParams()"
             class="cursor"><i class="fe fe-app-menu"></i> <span>Collections</span></a
          >
        </li> -->

        <!-- <li [routerLink]="['/vendors']" [queryParams]="queryParams()" routerLinkActive="active">
          <a [routerLink]="['/vendors']" [queryParams]="queryParams()"
             class="cursor"><i class="fe fe-vector"></i> <span>Vendors</span></a
          >
        </li>

        <li [routerLink]="['/products']" [queryParams]="queryParams()" routerLinkActive="active">
          <a [routerLink]="['/products']" [queryParams]="queryParams()"
             class="cursor"><i class="fe fe-wine-glass"></i> <span>Products</span></a
          >
        </li> -->

        <li
          [routerLink]="['/users']"
          [queryParams]='queryParams()'
          [ngClass]="isLinkActive('/users') ? 'active' : ''"
        >
          <a class='cursor'><i class='fe fe-users'></i> <span>Users</span></a>
        </li>

        <li
          [routerLink]="['/survey']"
          [queryParams]='queryParams()'
          [ngClass]="isLinkActive('/survey') ? 'active' : ''"
        >
          <a class='cursor'
          ><i class='fe fe-difference'></i> <span>Survey</span></a
          >
        </li>

        <li
          [routerLink]="['/complains']"
          [queryParams]='queryParams()'
          [ngClass]="isLinkActive('/complains') ? 'active' : ''"
        >
          <a class='cursor'><i class='fe fe-flag'></i> <span>Complain</span></a>
        </li>

        <!-- these commented functionality is complete but there is no need that's why we commented. -->
        <!-- <li>
          <a [routerLink]="['/orders']" [queryParams]="queryParams()"
            ><i class="fe fe-shopping-bag"></i> <span>Orders</span><span class="menu-arrow"></span>
          </a>
          <ul style="display: none">
            <li><a [routerLink]="['/orders']" [queryParams]="queryParamsOrder('ORDERED')">Ordered</a></li>
            <li><a [routerLink]="['/orders']" [queryParams]="queryParamsOrder('CANCELLED')">Cancelled</a></li>
            <li><a [routerLink]="['/orders']" [queryParams]="queryParamsOrder('RETURNED')">Returned</a></li>
          </ul>
        </li> -->

        <!-- <li [routerLink]="['/coupons']" [queryParams]="queryParams()" routerLinkActive="active">
          <a [routerLink]="['/coupons']" [queryParams]="queryParams()"
            ><i class="fe fe-gift"></i> <span>Coupons</span></a
          >
        </li> -->

        <!-- <li [routerLink]="['/payouts']" [queryParams]="queryParams()" routerLinkActive="active">
          <a [routerLink]="['/payouts']" [queryParams]="queryParams()"
            ><i class="fa fa-exchange"></i> <span>Payouts</span></a
          >
        </li>

        <li [routerLink]="['/content']" [queryParams]="queryParams()" routerLinkActive="active">
          <a [routerLink]="['/content']" [queryParams]="queryParams()"
            ><i class="fa fa-question-circle"></i> <span>Content</span></a
          >
        </li> -->

        <!-- <li [routerLink]="['/uploadProducts']" routerLinkActive="active">
          <a [routerLink]="['/uploadProducts']"><i class="fe fe-upload"></i> <span>Upload Products</span></a>
        </li> -->

        <!-- <li [routerLink]="['/slider']" routerLinkActive="active">
          <a [routerLink]="['/slider']"><i class="fe fe-activity"></i> <span>Manage Slider</span></a>
        </li>

        <li [routerLink]="['/prices']" routerLinkActive="active">
          <a [routerLink]="['/prices']"><i class="fe fe-list-order"></i> <span>Prices</span></a>
        </li>

        <li [routerLink]="['/filters']" routerLinkActive="active">
          <a [routerLink]="['/filters']"><i class="fe fe-filter"></i> <span>Fliters</span></a>
        </li> -->

        <!-- <li
          *ngIf="authData && authData.role == 'SUPER_ADMIN'"
          [routerLink]="['/admins']"
          [queryParams]="queryParams()"
          routerLinkActive="active"
        >
          <a [routerLink]="['/admins']" [queryParams]="queryParams()"
            ><i class="fe fe-shield"></i> <span>Admins</span></a
          >
        </li> -->
      </ul>
    </div>
  </div>
</div>
