<!-- Page Wrapper -->
<div class="page-wrapper">
  <div class="content container-fluid">
    <!-- Page Header -->
    <div class="page-header">
      <div class="row">
        <div class="col-sm-12">
          <h3 class="page-title" *ngIf="authData">
            Welcome {{ authData.name}}
          </h3>
          <ul class="breadcrumb">
            <li class="breadcrumb-item active">Dashboard</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- /Page Header -->

    <div class="row" *ngIf="dataIsLoaded">
      <div class="col-xl-3 col-sm-6 col-12" *ngFor="let item of cardData">
        <div class="card" [routerLink]="item.routerLink">
          <div class="card-body">
            <div class="dash-widget-header">
              <span
                [ngClass]="[item.textClass, item.borderClass]"
                class="dash-widget-icon"
              >
                <i [ngClass]="item.icon"></i>

              </span>
              <div class="dash-count">
                <h3>{{ item.count }}</h3>
              </div>
            </div>
            <div class="dash-widget-info">
              <h6 class="text-muted">{{ item.title }}</h6>
              <div class="progress progress-sm">
                <div
                  [ngClass]="item.progressClass"
                  class="progress-bar w-50"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="!dataIsLoaded">
      <div class="col-12">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </div>

    <div class="row" [hidden]="true">
      <div class="col-md-12 col-lg-6">
        <!-- Sales Chart -->
        <div class="card card-chart">
          <div class="card-header">
            <h4 class="card-title">Revenue</h4>
          </div>
          <div class="card-body">
            <div id="morrisArea"></div>
          </div>
        </div>
        <!-- /Sales Chart -->
      </div>
      <div class="col-md-12 col-lg-6">
        <!-- Invoice Chart -->
        <div class="card card-chart">
          <div class="card-header">
            <h4 class="card-title">Status</h4>
          </div>
          <div class="card-body">
            <div id="morrisLine"></div>
          </div>
        </div>
        <!-- /Invoice Chart -->
      </div>
    </div>
    <div class="row" [hidden]="true">
      <div class="col-md-6 d-flex">
        <!-- Recent Orders -->
        <div class="card card-table flex-fill">
          <div class="card-header">
            <h4 class="card-title">Doctors List</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-hover table-center mb-0">
                <thead>
                  <tr>
                    <th>Doctor Name</th>
                    <th>Speciality</th>
                    <th>Earned</th>
                    <th>Reviews</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of [1, 2, 3, 4, 5]">
                    <td>
                      <h2 class="table-avatar">
                        <a href="profile.html" class="avatar avatar-sm mr-2"
                          ><img
                            class="avatar-img rounded-circle"
                            src="../../../assets/user.png"
                            alt="User Image"
                        /></a>
                        <a href="profile.html">Dr. Ruby Perrin</a>
                      </h2>
                    </td>
                    <td>Dental</td>
                    <td>$3200.00</td>
                    <td>
                      <i class="fe fe-star text-warning"></i>
                      <i class="fe fe-star text-warning"></i>
                      <i class="fe fe-star text-warning"></i>
                      <i class="fe fe-star text-warning"></i>
                      <i class="fe fe-star-o text-secondary"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- /Recent Orders -->
      </div>
      <div class="col-md-6 d-flex">
        <!-- Feed Activity -->
        <div class="card card-table flex-fill">
          <div class="card-header">
            <h4 class="card-title">Patients List</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-hover table-center mb-0">
                <thead>
                  <tr>
                    <th>Patient Name</th>
                    <th>Phone</th>
                    <th>Last Visit</th>
                    <th>Paid</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of [1, 2, 3, 4, 5]">
                    <td>
                      <h2 class="table-avatar">
                        <a href="profile.html" class="avatar avatar-sm mr-2"
                          ><img
                            class="avatar-img rounded-circle"
                            src="../../../assets/user.png"
                            alt="User Image"
                        /></a>
                        <a href="profile.html">Charlene Reed </a>
                      </h2>
                    </td>
                    <td>8286329170</td>
                    <td>20 Oct 2019</td>
                    <td class="text-right">$100.00</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- /Feed Activity -->
      </div>
    </div>
    <div class="row" [hidden]="true">
      <div class="col-md-12">
        <!-- Recent Orders -->
        <div class="card card-table">
          <div class="card-header">
            <h4 class="card-title">Appointment List</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-hover table-center mb-0">
                <thead>
                  <tr>
                    <th>Doctor Name</th>
                    <th>Speciality</th>
                    <th>Patient Name</th>
                    <th>Apointment Time</th>
                    <th>Status</th>
                    <th class="text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of [1, 2, 3, 4, 5]">
                    <td>
                      <h2 class="table-avatar">
                        <a href="profile.html" class="avatar avatar-sm mr-2"
                          ><img
                            class="avatar-img rounded-circle"
                            src="../../../assets/img/logo-3_2.svg"
                            alt="User Image"
                        /></a>
                        <a href="profile.html">Dr. Ruby Perrin</a>
                      </h2>
                    </td>
                    <td>Dental</td>
                    <td>
                      <h2 class="table-avatar">
                        <a href="profile.html" class="avatar avatar-sm mr-2"
                          ><img
                            class="avatar-img rounded-circle"
                            src="../../../assets/user.png"
                            alt="User Image"
                        /></a>
                        <a href="profile.html">Charlene Reed </a>
                      </h2>
                    </td>
                    <td>
                      9 Nov 2019
                      <span class="text-primary d-block"
                        >11.00 AM - 11.15 AM</span
                      >
                    </td>
                    <td>
                      <div class="status-toggle">
                        <input
                          type="checkbox"
                          id="status_1"
                          class="check"
                          checked
                        />
                        <label for="status_1" class="checktoggle"
                          >checkbox</label
                        >
                      </div>
                    </td>
                    <td class="text-right">$200.00</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- /Recent Orders -->
      </div>
    </div>
  </div>
</div>
<!-- /Page Wrapper -->
